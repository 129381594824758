import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Sculpture = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>a kind of sculpture (project A)</h2>
        <p>websites often have distinct purposes; to inform, to connect, to entertain, what other expectations do we have for a website? Defy them, play with them. Consider the tools at your disposal; (mis)use them.</p>
        <p>Craft a website with care. It must not be a tool, no means for other ends, but exists for its own sake. This website is <i>a kind of sculpture</i>, which, upon (web) request, and delivery to a viewer’s device, is brought to life by the browser. This project should challenge our beliefs what a website can be; an object, a place to linger, an experience, a feeling, a story.</p>
        <br></br>
        <p>some inspiration:</p>
        <ul>
          <li>
            <p><a href="http://www.c3.hu/collection/form/" target="_blank">Form Art</a> by Alexei Shulgin (<a href="https://anthology.rhizome.org/form-art" target="_blank">Net Art Anthology Write-up</a>)</p>
          </li>
          <li>
            <p>Peter Luining's sound works (e.g. <a href="http://blog.ctrlaltdel.org/?s=osx" target="_blank">one</a>, <a href="http://ctrlaltdel.org/beep/" target="_blank">two</a>).</p>
          </li>
          <li>
            <p>Neal Agarwal's <a href="https://mobile.twitter.com/nealagarwal" target="_blank">Twitter</a> (might have to scroll for a while)</p>
          </li>
          <li>
            <p>Gatt.org by The Yes Men (<a href="https://anthology.rhizome.org/gatt-org" target="_blank">Net Art Anthology Write-up</a>).</p>
          </li>
          <li>
            <p><a href="http://www.teleportacia.org/war/" target="_blank">My Boyfriend Came Back from the War</a> by Olia Lialina (<a href="https://anthology.rhizome.org/my-boyfriend-came-back-from-the-war" target="_blank">Net Art Anthology Write-up</a>)</p>
          </li>
          <li>
            <p><a href="http://www.mouchette.org/" target="_blank">Mouchette</a> by Martine Neddam (<a href="https://anthology.rhizome.org/mouchette" target="_blank">Net Art Anthology Write-up</a>)</p>
          </li>
          <li>
            <p>Automatic Rain by <a href="http://jodi.org/" target="_blank">Jodi</a> (their website keeps changing). (<a href="https://anthology.rhizome.org/automatic-rain" target="_blank">Net Art Anthology Write-up</a>)</p>
          </li>
          <li>
            <p>LOVE by Group Z, Belgium (Michaël Samyn) (<a href="https://anthology.rhizome.org/love" target="_blank">Net Art Anthology Write-up</a>)</p>
          </li>
          <li>
            <p>The whole <a href="https://anthology.rhizome.org/" target="_blank">Net Art Anthology</a>.</p>
          </li>

        </ul>

        <br></br>
        <p>[added FS21]<br></br><i>optional take on this:</i> you may consider the webpage itself a sculpural work, or even think of the surrounding space of the browser/the computer; in other words, build a website that is meant to be seen in a specifc kind of place or in a specific kind of way (e.g. only to be seen upside down, while in an elevator, etc.)</p>
        <p>some inspiration (on this take):</p>
        <ul>
          <li>
            <p><a href="https://www.instagram.com/p/ByHCXMCn4ak/" target="_blank">Anti User friendly Match my four fingers</a> by Yehwan Song</p>
          </li>
          <li>
            <p><a href="https://www.instagram.com/p/BySkeDfnP_W/" target="_blank">Anti User Friendly | Series-03 | Inverted</a> by Yehwan Song</p>
          </li>
        </ul>

        <br></br>
        <br></br>

        <br></br>
        <p>[added FS22]<br></br>in this semester, design this project to by experienced on a phone screen primarily. consider the potential the phone brings; it has sensors (i.e. GPS, camera, accelerometer, etc.) and most of the times (i.e. friends at dinner, strangers in an elevator) many phones are available at the same time. the piece you build can make use of such things.</p>
        

        <br></br>
        <br></br>

        <p>even more inspirations (from past students):</p>
        <ul>
          <li>
            <p><a href="https://iris-666.github.io/abc-student-repo/projects/projectA-growth/" target="_blank">Growth</a> by Iris (FS20)</p>
          </li>

        </ul>


      </MiniLayout>
    </Layout>
  )
}



export default Sculpture
